<template>
  <div class="lot-predict-container">
    <h2>奖号预测</h2>
    <div
      v-for="item in content"
      :key="item.lotterycode">
      <router-link :to="generateRouteTo(item)">
        <img :src="require('@/assets/lot-logo/'+ item.lotterycode +'.png')" />
        <div class="lot-title">{{item.lotteryname}}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getDisplay } from '@/api/client/display'
import resStatus from '@/constants/resStatus'

export default {
  name: 'lotPredict',
  data () {
    return {
      content: [],
      predictQuery: {
        type: 'predict',
        num: '5'
      }
    }
  },
  methods: {
    getPredictLot () {
      getDisplay(this.predictQuery)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.content = res.data
          }
        })
    },
    generateRouteTo (item) {
      return {
        name: 'LotteryChartDetail',
        query: {
          id: item.lotterycode,
          chart: 'numAnalysis'
        }
      }
    },
    getLogoUrl (item) {
      if (item.image_2c) {
        return item.image_2c
      }
      return ''
    }
  },
  created () {
    this.getPredictLot()
  }
}
</script>

<style lang="scss" scoped>
.lot-predict-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-sizing: border-box;
  width: 100%;
  height: 484px;
  margin-top: 20px;
  text-align: left;
  padding: 30px 0 25px 30px;
  border-radius: 5px;
  h2 {
    color: #000000;
    text-align: left;
    font-size: 21px;
  }
  img {
    width: 60px;
    float: left;
    margin-right: 15px;
  }
  .lot-title {
    margin-top: 20px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4F4F4F;
  }
}
@media (max-width: 575.98px) {}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-predict-container {
    padding: 20px 0 20px 20px;
    h2 {font-size: 18px;}
    img {width: 50px;}
    .lot-title {
      margin-top: 15px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {
  .lot-predict-container {
    width: 386px;
  }
}
</style>
