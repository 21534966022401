<template>
  <div class="commercial-content">
    <div class="commercial-item">
      <a
        v-for="(ad, idx) in ads"
        :key="idx"
        class="ad-container"
        :class="Object.keys(ads).length > 1 ? 'ad-container-gap' : ''"
        :href="ad[0].href"
        :style="{
          backgroundImage: `url('${$common.resSrc(ad[0].photo)}')`,
          height: `${adHeight}px`,
        }"
        @click="sendVisit(ad[0].id)"
      >
      </a>
    </div>
  </div>
</template>

<script>
import { visit } from '@/api/client/visit'
import { getBanners } from '@/api/client/banner'
import resStatus from '@/constants/resStatus'
import { isMobile } from '@/utils/device'

export default {
  name: 'commercial',
  props: {
    ids: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visitQuery: {
        to: 'ad',
        uuid: '',
        id: '',
      },
      bannerQuery: {
        position_ids: this.ids,
      },
      ads: [],
    }
  },
  computed: {
    adHeight() {
      const sw = window.innerWidth
      let adWidth = sw > 1200 ? 1200 : sw
      if (sw >= 768 && sw < 992) {
        adWidth = sw * 0.98 - 60
        return adWidth * 0.33
      }
      return isMobile() ? adWidth - 80 : adWidth * 0.3216667
      // return isMobile() ? (adWidth - 80) * 0.36232 : adWidth * 0.3217 * 0.3627
    },
  },
  methods: {
    sendVisit(id) {
      this.visitQuery.id = id
      visit(this.visitQuery)
    },
    getCommercials() {
      getBanners(this.bannerQuery).then((res) => {
        if (res.status === resStatus.OK) {
          this.$emit('needShowHr', false)
          if (res.data.length > 0 || Object.keys(res.data).length > 0) {
            this.ads = res.data
            this.$emit('needShowHr', true)
          }
        }
      })
    },
  },
  created() {
    this.getCommercials()
  },
}
</script>

<style lang="scss" scoped>
.commercial-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .commercial-item {
    width: 100%;
    margin-bottom: 10px;
    .ad-container-gap {
      margin-bottom: 10px;
    }
    a {
      display: block;
      width: inherit;
      // min-height: 140px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 575.98px) {
}
@media (min-width: 576px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .commercial-content {
    .commercial-item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      .ad-container-gap {
        margin-bottom: 0;
      }
      & a {
        margin: 0 5px;
        &:nth-child(1) {
          margin-left: 0;
        }
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
}
@media (min-width: 1200px) {
  .commercial-content {
    // gap: 10px;
    .commercial-item {
      width: 386px;
    }
  }
}
</style>
