<template>
  <div class="home-container">
    <div
      v-if="isPage('LotteryInfo')"
      class="info-tool-container">
      <lot-info class="lot-info"/>
      <div class="right-container">
        <div class="lot-tool-box">
          <lot-tool class="lot-tool" />
        </div>
        <lot-predict />
        <advertise :ids="ids"/>
      </div>
    </div>
    <router-view 
      v-if="isPage('LotteryInfoDetail')"
      v-bind="$attrs" />
  </div>
</template>

<script>
import LotTool from '@/components/LotteryInfo/LotTool'
import LotInfo from './lotInfo'
import lotPredict from '@/components/LotteryInfo/LotteryPredict'
import advertise from '@/components/LotteryInfo/advertise'

export default {
  name: 'LotteryInfo',
  components: {
    LotTool,
    LotInfo,
    lotPredict,
    advertise
  },
  data () {
    return {
      ids: '6,7,8'
    }
  },
  methods: {
    isPage (routeName) {
      return this.$route.name === routeName
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  .info-tool-container {
    display: flex;
    justify-content: space-around;
    .ad-container {
      width: 386px;
      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .home-container {
    width: 100%;
    padding: 0;
    background: #FFF;
    // gap: 20px;
    .info-tool-container {
      height: 100%;
      // gap: 20px;
      .lot-info {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        margin-right: 0;
      }
      .right-container{
        display: none;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .home-container {
    width: 100%;
    padding: 0;
    background: #FFF;
    // gap: 20px;
    .info-tool-container {
      height: 100%;
      // gap: 20px;
      .lot-info {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        margin-right: 0;
      }
      .right-container{
        display: none;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .home-container {
    width: 98%;
    padding: 20px 8px;
    // gap: 20px;
    .info-tool-container {
      height: 100%;
      justify-content: flex-start;
      // gap: 20px;
      .lot-info {
        width: calc((100% - 18px) * 0.66);
        min-width: calc((100% - 18px) * 0.66);
        height: fit-content;
        min-height: fit-content;
        margin-right: 18px;
      }
      .right-container{
        width: calc((100% - 18px) * 0.34);
        .lot-tool-box {
          display: flex;
          width: 100%;
          min-height: 484px;
        }
        .ad-container {
          margin-top: 20px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .home-container {
    width: 97%;
    padding: 20px 5px;
    // gap: 20px;
    .info-tool-container {
      height: 100%;
      justify-content: flex-start;
      // gap: 20px;
      .lot-info {
        width: calc((100% - 20px) * 0.66);
        min-width: calc((100% - 20px) * 0.66);
        height: fit-content;
        min-height: fit-content;
        margin-right: 20px;
      }
      .right-container{
        width: calc((100% - 20px) * 0.34);
        .lot-tool-box {
          display: flex;
          min-height: 484px;
        }
        .ad-container {
          margin-top: 20px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .home-container {
    padding: 20px 0;
    // gap: 20px;
    .info-tool-container {
      height: 100%;
      // gap: 20px;
      .lot-info {
        width: 792px;
        min-width: 792px;
        height: fit-content;
        min-height: fit-content;
        margin-right: 20px;
      }
      .lot-tool-box {
        display: flex;
        min-height: 484px;
      }
      .ad-container {
        margin-top: 20px;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
