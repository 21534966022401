<template>
  <div class="info-container">
    <div class="header">
      <div class="left">
        <h2>彩票资讯</h2>
        <ul>
          <li
            v-for="tab in tabs"
            :key="tab.id"
            @click="handleClickTab(tab)"
            :class="isActive(tab) ? 'active' : ''">
            {{ tab.context }}
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <!-- <transition name="lot-info"> -->
        <keep-alive>
          <component
            :is="curTab"
            :content="content" />
        </keep-alive>
      <!-- </transition> -->
    </div>
    <Pagination
      class="news-list-pagination"
      :curPage="curPage"
      :pageSize="pageSize"
      :totalPages="totalPages"
      @handleChangePage="handleChangePage" />
  </div>
</template>

<script>
import { lotInfoTabs } from '@/constants/home'
import LotNews from './LotNews'
import LotTrick from './LotTrick'
import LotShare from './LotShare'
import { goPage } from '@/utils/route'
import resStatus from '@/constants/resStatus'
import Pagination from './Pagination'
import { getNewsList } from '@/api/client/lotNews'
import { getTricksList } from '@/api/client/lotTricks'
import { getArticleList } from '@/api/client/lotShare'

export default {
  name: 'lotInfo',
  components: {
    LotNews,
    LotTrick,
    LotShare,
    Pagination
  },
  data () {
    return {
      curTab: this.initCurTab(),
      tabs: lotInfoTabs,
      paginationDatas: {
        LotNews: {
          query: {
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        },
        LotTrick: {
          query: {
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        },
        LotShare: {
          query: {
            page: 1,
            pageSize: 10
          },
          result: {
            data: [],
            totalPages: 0
          }
        }
      }
    }
  },
  computed: {
    content: {
      get () {
        return this.getPageData('content')
      }
    },
    curPage: {
      get () {
        return this.getPageData('curPage')
      }
    },
    pageSize: {
      get () {
        return this.getPageData('pageSize')
      }
    },
    totalPages: {
      get () {
        return this.getPageData('totalPages')
      }
    }
  },
  methods: {
    getPageData (dataName) {
      var tmpArr = this.paginationDatas[this.curTab]
      switch (dataName) {
        case 'content':
          return tmpArr.result.data
        case 'curPage':
          return tmpArr.query.page
        case 'pageSize':
          return tmpArr.query.pageSize
        case 'totalPages':
          return tmpArr.result.totalPages
        default:
          break
      }
    },
    initCurTab () {
      if (this.$route.query.tab) {
        return this.$route.query.tab
      }
      return 'LotNews'
    },
    handleClickTab (tab) {
      goPage({
        name: 'LotteryInfo',
        query: {
          tab: tab.id
        }
      })
    },
    isActive (tab) {
      return tab.id === this.curTab
    },
    emptyResult () {
      this.paginationDatas.LotNews.result = {
        data: [],
        totalPages: 0
      }
      this.paginationDatas.LotTrick.result = {
        data: [],
        totalPages: 0
      }
      this.paginationDatas.LotShare.result = {
        data: [],
        totalPages: 0
      }
    },
    updateResult () {
      this.emptyResult()
      let getList = this.curTab === 'LotNews' ? getNewsList :
                    this.curTab === 'LotTrick' ? getTricksList : getArticleList
      getList(this.paginationDatas[this.curTab].query)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.paginationDatas[this.curTab].result = res
          }
        })
    },
    handleChangePage (page) {
      this.paginationDatas[this.curTab].query.page = page
      this.updateResult()
    }
  },
  created () {
    this.updateResult()
  },
  watch: {
    '$route.query.tab' () {
      this.curTab = this.initCurTab()
      this.updateResult()
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      // gap: 64px;
      h2 {
        color: #000000;
        margin-right: 64px;
      }
      ul {
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #000000;
        }
        .active {
          color: #005FDE;
          border-bottom: 3px solid #005FDE;
        }
      }
    }
  }
  .content {
    flex-grow: 99;
  }
  .news-list-pagination {
    margin: 40px 0 60px;
  }
}
@media (max-width: 575.98px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 0;
    .header {
      height: fit-content;
      box-sizing: border-box;
      padding-top: 0;
      border-bottom: 1px solid #E0E0E0;
      .left {
        width: 100%;
        flex-direction: column;
        margin-left: 0;
        h2 {
          width: 100%;
          font-weight: 500;
          font-size: 21px;
          line-height: 30px;
          color: #FFF;
          background-color: #56CCF2;
          text-align: center;
          padding: 18px 0;
          margin-right: 0;
        }
        ul {
          width: 100%;
          background-color: #FFF;
          justify-content: center;
          li {
            width: max-content;
            height: fit-content;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: #4F4F4F;
            padding: 15px 12px;
            margin-right: 15px;
            &:last-child{margin-right: 0;}
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 0;
    .header {
      height: fit-content;
      box-sizing: border-box;
      padding-top: 0;
      border-bottom: 1px solid #E0E0E0;
      .left {
        width: 100%;
        flex-direction: column;
        margin-left: 0;
        h2 {
          width: 100%;
          font-weight: 500;
          font-size: 21px;
          line-height: 30px;
          color: #FFF;
          background-color: #56CCF2;
          text-align: center;
          padding: 18px 0;
          margin-right: 0;
        }
        ul {
          width: 100%;
          background-color: #FFF;
          justify-content: center;
          li {
            width: max-content;
            height: fit-content;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            color: #4F4F4F;
            padding: 15px 12px;
            margin-right: 15px;
            &:last-child{margin-right: 0;}
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 62px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        h2 {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 10px;
        }
        ul {
          li {
            width: 100px;
            height: 52px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 80px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        h2 {
          font-weight: 500;
          font-size: 21px;
        }
        ul {
          li {
            width: 100px;
            height: 70px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
@media (min-width: 1200px) {
  .info-container {
    min-height: 484px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      height: 80px;
      box-sizing: border-box;
      padding-top: 10px;
      border-bottom: 1px solid #E0E0E0;
      .left {
        margin-left: 30px;
        h2 {
          font-weight: 500;
          font-size: 21px;
        }
        ul {
          li {
            width: 100px;
            height: 70px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .right {
        a { color: inherit; }
        margin-right: 30px;
        color: #828282;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
</style>
