<script>
import demo from '../LotNews/index'
// import { visit } from '@/api/client/visit'
// import { fakeTrick } from '@/constants/fakeTrick'
// import { contentLimit } from '@/utils/contentLimit'
// import { isMobile } from '@/utils/device'

export default {
  ...demo,
  // props: {
  //   content: {
  //     type: Array,
  //     required: true
  //   }
  // },
  // computed: {
  //   news: {
  //     get () {
  //       return contentLimit(this.content)
  //     }
  //   },
  //   isMobile () {
  //     return isMobile()
  //   }
  // },
  data () {
    return {
      pagetype: 'user_article',
      visitQuery: {
        to: '',
        id: ''
      }
    }
  },
  // methods: {
  //   sendVisit (id) {
  //     this.visitQuery.to = this.pagetype === 'trick' ? 'winskill' : this.pagetype
  //     this.visitQuery.id = id
  //     visit(this.visitQuery)
  //   }
  // }
}
</script>
