<template>
  <div class="lot-news-container">
    <ul class="lot-news-list">
      <li class='all-list'
        v-for="(item, idx) in news"
        :key="idx + item.created_at">
        <router-link
          class="single-list"
          :target="isMobile ? '_self' : '_blank'"
          :to="{ name: 'LotteryInfoDetail', query: { id: item.id, type: pagetype, num: 5 }}"
          @click.native="sendVisit(item)">
          <div class="image-container">
            <div class="image-box">
              <!-- <i class="el-icon-picture"></i> -->
              <img :src="defaultImage" v-real-img="getPhotoUrl(item)" />
            </div>
          </div>
          <div class="content-container">
            <div class="home-title-first">{{ item.title }}</div>
            <div
              v-if="!isMobile"
              class="content"
              v-html="item.content">
            </div>
            <div class="content-bottom">
              <div class="content-date">{{ confirmDate(item) }}</div>
              <div v-if="showComments" class="content-comment">
                <!-- <b-icon icon="chat"></b-icon> -->
                <img src="@/assets/comment.png">
                <span>{{item.comments}}</span>
              </div>
              <div 
                v-if="showComments"
                class="content-like"
                :class="item.has_like ? 'content-like-act' : ''">
                <img :src="item.has_like ? require('@/assets/thumb-up-act.png') : require('@/assets/thumb-up.png')">
                <span>{{item.likes}}</span>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// import { fakeNews } from '@/constants/fakeNews'
import { visit } from '@/api/client/visit'
import { contentLimit } from '@/utils/contentLimit'
import { isMobile } from '@/utils/device'

export default {
  name: 'lotNews',
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      pagetype: 'news',
      visitQuery: {
        to: '',
        id: ''
      }
    }
  },
  computed: {
    news () {
      let list = this.content
      return contentLimit(list)
    },
    isMobile () {
      return isMobile()
    },
    showComments () {
      return this.pagetype === 'user_article'
    },
    defaultImage () {
      return require(`@/assets/share-default.jpg`)
    }
  },
  methods: {
    sendVisit (data) {
      this.visitQuery.to = this.pagetype === 'trick' ? 'winskill' : this.pagetype
      this.visitQuery.id = data.id
      visit(this.visitQuery)
    },
    getPhotoUrl (item) {
      return item.photo !== '' ? item.photo : item.photo_u !== "" ? item.photo_u : ''
      
    },
    confirmDate (item) {
      return item.showdate ? item.showdate : item.start_at ? item.start_at.split(' ')[0] : item.created_at.split(' ')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.lot-news-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > :not(:first-child) {
    border-top: 1px solid #e0e0e0;
  }
  .all-list {
    .single-list {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        .content-container {
          .home-title-first {color: #005FDE;}
        }
      }
      .image-container {
        .image-box {
          width: 135px;
          height: 110px;
          background-color: #f0f3f7;
          border-radius: 5px;
          object-fit: cover;
          overflow: hidden;
          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
          }
        }
      }
      .content-container {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 99;
        box-sizing: border-box;
        text-align: left;
        letter-spacing: 0em;
        font-style: normal;
        padding: 5px 0;

        .home-title-first {
          color: #333333;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; 
        }
        .content {
          color: #000000;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          margin: 10px 0px 10px 0px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        .content-bottom {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > div {
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            & img {
              width: auto;
              height: 15px;
              vertical-align: text-top;
            }
            & span {
              display: inline-block;
              margin-left: 5px;
            }
          }
          .content-date {
            color: #bdbdbd;
            margin-right: 20px;
          }
          .content-comment {
            color: #828282;
            margin-right: 15px;
          }
          .content-like {
            color: #828282;
            &-act {
              color: #005fde;
            }
          }
        }
      }
    }
  }
}
.lot-news-list:first-child {
  border-top: none;
}
@media (max-width: 575.98px) {
  .lot-news-list {
    padding: 5px 15px;
    .all-list{
      .single-list {
        height: 105px;
        min-height: 105px;
        box-sizing: border-box;
        padding: 15px 0;
        margin: 0;
        &:hover{
          cursor: pointer;
          .content-container {
            a {
              color: #005FDE;
            }
          }
        }
        .image-container{
          margin-right: 13px;
          .image-box {
            width: 100px;
            height: 70px;
            border-radius: 3px;
          }
        }
        .content-container {
          .home-title-first {
            font-size: 15px;
            line-height: 21px;
            color: #333333;
          }
          .content {display: none;}
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lot-news-list {
    padding: 5px 15px;
    .all-list {
      .single-list {
        height: 105px;
        min-height: 105px;
        box-sizing: border-box;
        padding: 15px 0;
        margin: 0;
        &:hover{
          cursor: pointer;
          .content-container {
            a {
              color: #005FDE;
            }
          }
        }
        .image-container{
          margin-right: 13px;
          .image-box {
            width: 100px;
            height: 70px;
            border-radius: 3px;
          }
        }
        .content-container {
          .home-title-first {
            font-size: 15px;
            line-height: 21px;
            color: #333333;
          }
          .content {display: none;}
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .lot-news-list {
    padding: 0 20px;
    .all-list {
      .single-list{
        height: 154px;
        min-height: 154px;
        box-sizing: border-box;
        padding: 24px 0;
        margin: 0;
        &:hover{
          cursor: pointer;
          .content-container {
            a {
              color: #005FDE;
            }
          }
        }
        .image-container{
          margin-right: 20px;
        }
        .content-container {
          .home-title-first {
            font-size: 16px;
            line-height: 23px;
            color: #333333;
          }
          .content {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .lot-news-list {
    .all-list a{
      height: 154px;
      min-height: 154px;
      box-sizing: border-box;
      padding: 24px 0;
      margin: 0 30px;
      &:hover {
        cursor: pointer;
        .content-container {
          a {
            color: #005fde;
          }
        }
      }
      .image-container {
        margin-right: 25px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .lot-news-list {
    .all-list a{
      height: 154px;
      min-height: 154px;
      box-sizing: border-box;
      padding: 24px 0;
      margin: 0 30px;
      &:hover {
        cursor: pointer;
        .content-container {
          a {
            color: #005fde;
          }
        }
      }
      .image-container {
        margin-right: 25px;
      }
    }
  }
}
</style>
